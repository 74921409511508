
@font-face{
  font-family:Merriweatherbold;
  src:url(./fonts/Merriweather-Bold.ttf);
  
}
@font-face{
  font-family:Merriweatherlight;
  src:url(./fonts/Merriweather-Light.ttf);
  
}
@font-face{
  font-family:Merriweatherregular;
  src:url(./fonts/Merriweather-Regular.ttf);
  
}
@font-face{
  font-family:poppinsbold;
  src:url(./fonts/Poppins-Bold.ttf);
  
}
@font-face{
  font-family:poppinslight;
  src:url(./fonts/Poppins-Light.ttf);
  
}
@font-face{
  font-family:poppinsregular;
  src:url(./fonts/Poppins-Regular.ttf);
  
}

.navbar-light .navbar-toggler-icon {

background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
border:none !important;
}
button:focus {
  outline: 1px dotted;
  outline: none !important;
}

.navbar-light .navbar-toggler {
 
  border-color:transparent !important; 
}
.navbar{
  margin-top:2.5%;
  margin-bottom:5%;
}
.show{
  background-color:black;
  padding:20px;
}
.navbar-brand{
  color:white !important;
  font-family: Merriweatherlight;
  font-size:30px !important;
}
.collapsing{
  transition:none !important;
}
.logo{
  font-family: Merriweatherbold;
}
.nav-link{
  color:white !important;
  font-size:17px;
  font-family: Arial, Helvetica, sans-serif;
}
.canine-body{
  padding-left:7% !important;
  padding-right:7% !important;
}
.banner {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: url(./images/banner.png);
  background-repeat: no-repeat;
  border: 0px;
  border-radius: 0px;
}
 
@media (max-width:1536px){
  .banner {
  width: 100%;
  height: 125vh;
  background-size: cover;
  background-image: url(./images/banner.png);
  background-repeat: no-repeat;
}}
.bannerContent{
  background-image: url(./images/background.jpg); 
  background-position:center;
  background-size:cover;
  opacity:0.9;
  height:89%;
  color:white;
  padding:5%;
   width:550px;
   margin-top: 60px;
} 
.bannerContent2{
  background-image: url(./images/background2.png);
  background-position:center;
  background-size:cover;
  /* opacity:0.9; */
  height:89%;
  color:white;
  padding:5%;
   
}
.parent{
  background-image: url(./images/Parents.png);
  background-position:center;
  background-size:cover;
  /* opacity:0.9; */
  height:89%;
  color:white;
  padding:5%;
  
}
.dogs{
  background-image: url(./images/dogs.png);
  /* background-position:center; */
  background-size:cover;
  height:100%; 
  padding:5%;
  width: 100%;    
}

.bannerheading{
  font-size:30px;
  line-height: 35px;
  text-align:left;
  color:#ffff;
  font-family:Arial, Helvetica, sans-serif ;
}
.bannerheading1{
  font-family:Arial, Helvetica, sans-serif ;
}
.bannertext{
  font-size:18px;
  line-height: 30px;
  color:#ffff;
  font-family:Arial, Helvetica, sans-serif ;
}
.Navbar{
  position: fixed;
  /* top:0px !important; */
}

.footer{
  background-color: rgb(33, 33, 33) ;
  padding-top:20px;
  padding-bottom:20px;
  color:white ;
}

.link{
  padding-left:2%;
  color:white;
}

.link:hover{
  color:rgb(69, 165, 153);
 
}
.useheading{
  color:rgb(69, 165, 153);
  font-size: 45px;
  line-height: 55px;
  font-family:Arial, Helvetica, sans-serif ;
  margin-top:0px !important;
  padding-bottom:30px;
  
}
.useheading1{
  color:black;
  font-size: 45px;
  line-height: 55px;
  font-family:Arial, Helvetica, sans-serif ;
  margin-bottom:0px !important;
  padding-top:50px;
  
}

.canineuse{
  padding-top:100px !important;
  padding-bottom:100px !important;
}
.canine-img{
  width:60%;
  text-align:center;

}
.usetext{
  font-size:18px;
  line-height: 30px;
  font-family:Arial, Helvetica, sans-serif ;
  
}
.lowcost{
 
  border-right:0.1px solid rgba(33, 33, 33, 0.315);
  border-left:0.1px solid rgba(33, 33, 33, 0.335);
}



.row{
  padding:0px !important;
  margin:0px !important;
}

.icon{
  color:rgb(69, 165, 153);
}

.nav-link:hover{
   color:white !important;
   /* font-weight:bolder; */
}

.canine-lab{
  background-color: #bd9180;
  width:100%;
  height: 30px;
  /* height:10vh; */
  background-position:center;
  background-size:cover;
  /* position: sticky; */
  padding:100px;
   /* padding-bottom:100px;  */
   /* background-size:100%;  */
  /* background-repeat:no-repeat; */
}

.fullcontrol{
  padding-top:30px;
  font-family: Arial, Helvetica, sans-serif ;
  font-size:28px;
  line-height: 40px;
  color:white;
  margin-left: 30px;
  
}

.fullcontroltext{
  font-family: Arial, Helvetica, sans-serif ;
  font-size:15px;
  line-height: 30px;
  color:white;
  padding:20px;
}

.full{
 
 position:absolute;
 bottom:0px;
 left:10%;
 right:10%;
}
.iconbackground{
  text-align: center;
  width:100px;
  height:100px;
  border-radius: 100%;
  background-color: white;
  display:inline-block;
  position: relative;
  margin-left: 70px;
}
.icon{
  width:65px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.labtest{
  border-right: 1px solid rgba(0, 0, 0, 0.123);
}
.title{
  width:100%;
  padding:5px;
  background-color: white;

}
.title1{
  font-size:25px;
  text-align:left;
  font-family:Merriweatherbold ;
  margin-bottom: 0px;
}



.betterheading{
  font-family: Merriweatherbold ;
  font-size:30px;
  line-height:50px;
  color:rgb(69, 165, 153);

}
.bettertext{
  font-family:poppinslight;
  font-size:18px;
  line-height: 30px;
}
.container{
  padding-top:3%;
  
}
.label{
  padding:5px;
  border:1px solid white;
   width:70%;
   display:inline-block;
   margin:'10px';
}
.shape{
  width:60px;
  padding:10px;
}
.copy-right{
  font-size:12px !important;
  text-align:right !important;
}
.additional{
  background-color:rgb(190, 145, 126);
  display:none !important;
}
.innerpage{
  height:300px;
  background-position: top;
  background-size: 100%;
}
.features-heading{
  color:white;
  font-family: Merriweatherbold ;
text-align:left;
}
.features-text{
  padding:2%;
  font-size:18px;
  font-family:poppinslight;
  line-height:30px;
  padding-bottom:100px;
}
.feature{
  padding-top:50px 
  

}
.submitbutton{
  background-color: rgb(69, 165, 153) !important;
  border-color:rgb(69, 165, 153) !important;
}
.form-text{
  
  font-family: poppinsregular;
  padding:10px;
  font-size:30px;
  line-height: 32px;
  /* color:rgb(248, 251, 251); */
  margin-bottom:30px;
  text-align:center;
}
.form{
  text-align:center !important;
}
.contactimg{
  background-image: url(./images/monitor_v1@3x.jpg);
  width:100%;
  background-position: top;
  background-size: 100%;
  background-repeat:no-repeat;
  height:500px;
}
.contactsection{
  padding:5%;
  padding-top:2%;
}
.show{
  background-color: rgba(0, 0, 0, 0.37);
}
.contactrow{
  padding-top:5% !important;
  
  
}
.contactlabel{

    padding:5px;
    border:1px solid white;
     width:80%;
     display:inline-block;
     margin:'10px';
 
}
.contacttitle{
  width:100%;
  padding:5px;
  background-color: white;

}
.contacttitle1{
  font-size:17px;
  text-align:left;
  font-family:Merriweatherbold ;
  margin-bottom: 0px;
}
.contactshape{
  width:40px;
  padding:10px;
}
.form-control{
  font-size: 14px !important;
    padding: 0px !important;
    font-family: poppinsregular;
    height: 30px !important;
}
.form-label{
  margin-bottom: 0rem !important;
  font-size:14px;
  font-family: poppinsregular;
}
.form-group{
  text-align:left;
  margin-bottom:10px !important;
}
.textarea{
  height:50px !important;
}
@media(max-width:1920px) and (max-height:1080px){
  .canine-lab{
    height:153vh;
  }
}
@media(max-width:1920px) and (max-height:1200px){
  .canine-lab{
    height:137vh;
  }
}
@media(max-width:1280px) and (max-height:950px){
  .canine-lab{
    height:117vh ;
  }
}
@media(max-width:490px){
  .canine-lab{
    height:43vh !important;
  }
  .labtest{
    border-right: none !important;
  }
}

@media (max-width:768px){
  .bannerContent{
    width:300px !important;
    padding:20px;
  }
  .bannerheading{
    font-size:30px !important;
    line-height:35px !important;
  }
  .bannertext{
    font-size:12px !important;
    line-height:18px !important;
  }
  .canine-img{
    width:50% !important;
  }
  .useheading{
    font-size:30px !important;
  }
  .useheading1{
    font-size:30px !important;
  }
  .navbar{
    margin-top:0%;
  }
  .canine-img{
    width:60% !important;

  }
  .useheading1{
    font-size:30px !important;
    text-align:center !important;
  }
  .useheading{
    font-size:30px !important;
    text-align:center !important;
  }
  .betterheading{
    font-size:28px !important;
  }
  .canine-lab{
    height:74vh !important;
    padding:55px !important;
  }
  .full{
    flex-wrap:nowrap !important;
  }
  .title1{
    font-size:16px !important;
  }
  .iconbackground{
    width:50px !important ;
    height:50px !important;
  }
  .icon{
    width:33px !important;
  }
  .canine-body{
    padding-left: 5% !important;
    padding-right: 5%!important ;
  }
  .labtest{
    padding-left:5px !important;
    padding-right:5px !important ;
  }
  .fullcontrol{
    font-size:17px !important;
  }
  .fullcontroltext{
    margin-bottom:0px !important ;
    font-size:13px !important;
    line-height:20px !important;
  }
  .additional{
    display:block !important;
  }
  .full{
    display:none !important;
  }
  .titlebody{
    position:absolute !important;
    bottom:0px !important;
    text-align: center;
    flex-wrap: nowrap;
    display:none !important;
  }
  .label{
    width:75% !important;
  }
  .navbar{
    margin-top:0% !important;
  }
  .labtest{
    padding:5% !important;
  }
  .innerpage{
    height:300px;
  }
  .features-text{
    padding:6% !important;
  }
  .labtest{
    border-right: none !important;
  }
  .features-section{
    height:auto;
  }
  .form{
    padding-bottom:20px;
  }
  .formrow{
    padding-left:15% !important;
    padding-right:15% !important;
  }
  .form-text{
    text-align:center;
  }
  
}

@media (max-width:1440px){
  .canine-lab{
    height:218vh;

  }
  .label{
    width:100% ;
  }
  .fullcontrol{
    padding-top:10px ;

  }
  .fullcontroltext{
    padding:0px ;
  }
  .canine-img{
    width:90% ;
  }
  .useheading1{
    padding-top: 0px ;
  }
  .useheading{
    padding-bottom:10px ;
  }
.usetext{
  font-size:15px ;
  line-height:27px ;
}
.bettertext{
  font-size:15px ;
  line-height:27px ;
}
.bannerContent{
  width:400px;

}
.bannerheading{
  font-size:30px;
  line-height: 35px;
}
.bannertext{
  font-size:15px;
line-height: 20px;
}
.nav-link{
  font-size:16px ;
}
.form-text{
  font-size:19px;
  line-height:25px;
}
.contactimg{
  height:450px;
}
.formrow{
  padding-left:5% ;
  padding-right:5% ;
}
.contactlabel{
  width:100%;
}

}
@media (min-width:1440px){
  .formrow{
    padding-left:5% !important;
    padding-right:5% !important;
  }
}
@media (max-width:1024px){
  .bannerheading{
    font-size:30px;
    line-height: 20px;
  }
  .bannertext{
    font-size:15px;
    line-height: 20px;
  }
  .nav-link{
    font-size:15px ;
  }
  .canine-img{
    width:100%;
  
  }
  .useheading1{
  font-size:35px ;
  }
  .useheading{
    padding-bottom: 0px ;
    font-size:35px ;
  }
  .usetext{
    font-size: 13px ;
    line-height: 22px ;
  }
  .bettertext{
    font-size: 13px ;
    line-height: 22px ;
  }
  .betterheading{
    padding-bottom: 0px ;
    font-size:35px;
  }
  .container{
    padding-top:20px ;
  }
  .canine-lab{
    height:154vh;
  }
  .shape{
    width:42px;
  }
  .title1{
    font-size:18px;
  }
  .iconbackground{
    width:75px;
    height:75px;
  }
  .icon{
    width:45px;
  }
  .fullcontrol{
    padding-top:0px !important;
    margin-bottom:0px ;
    font-size:20px ;
    line-height:40px ;
  }
  .fullcontroltext{
    font-size:12px ;
    line-height: 15px ;
  }
  .canineuse{
    padding-top:50px ;
    padding-bottom:50px ;
  }
  .features-text{
    font-size:16px;
    line-height: 25px;
  }
  .features-heading{
    padding-top:5%;
    font-size:35px;
  }
  .features-text{
    padding:10%;
  }
 .form-text{
   font-size:19px;
   line-height:28px;
   padding:0px;
 }
 .formrow{
  padding-left:0% ;
  padding-right:0% ;
}
.contacttitle1{
  font-size:12px;
}
.contactshape{
  width:21px;
  padding:3px;
}
.form-group{
  margin-bottom:4px !important;
}

}
@media (max-width:618px){
  .navbar-brand{
    font-size:15px !important ;
  }
  .navbar-toggler-icon{
    width:1em !important ;
    height:1em !important ;
  }
  .canine-body{
    padding-left:2% !important;
    padding-right:2% !important;
  }
  .bannerheading{
    font-size:22px !important ;
    line-height: 23px !important;
  }
  .line{
    margin-top:-15px !important;
  }
  .bannertext{
    font-size:12px !important;
    line-height: 13px !important;
  }
  .canineuse{
    padding-top:20px ;
    padding-bottom:0px !important;
  }
  .column{
    padding-left:0px !important;
    padding-right:0px !important;
  }
  .labtest{
    border-right: none !important;
  }
  .formrow{
    padding-left:0% !important;
    padding-right:0% !important;
  }
}

@media (max-width:425px){
  .betterheading{
    font-size:25px !important;
    line-height:33px !important;
  }
  .useheading1{
    font-size:25px !important;
  
  }
  .useheading{
    font-size:25px !important;
    margin-top:-20px !important;
  }
  .canineuse{
    padding-top:0px;
  }
  .canine-lab{
    height:42vh !important;
  }
  .label{
    width:100% !important;
  }
  .copy-right{
    text-align:center !important;
  }
  .footer-link{
    text-align:center;
  }
  .innerpage{
    height:219px;
  }
  .features-heading{
    font-size:23px;
    padding-top:15%;
  }
  .features-text{
    font-size:13px;
  }
  .labtest{
    border-right: none !important;
  }
  .formrow{
    padding-left:5% !important;
    padding-right:5% !important;
  }
  .contactrow{
    position:absolute !important;
    bottom:0px !important;
    text-align: center;
    flex-wrap: nowrap;
    
  }
  .contactimg{
    height:300px !important;
  }
  .contactlabel{
    width:51%;
  }
}
@media (max-width:375px){
  .narbar{
    padding:0rem !important;
  }
  .bannertext{
    margin-bottom:0px !important;
  }
  .bannerContent{
    padding:10px !important;
  }
  .canineuse{
    padding-top:0px !important;
  }
  .canine-lab{
    height:37vh !important;
  }
  .innerpage{
    height:215px;
  }
  .features-text{
    font-size:14px;
  }
  .labtest{
    border-right: none !important;
  }
  .formrow{
    padding-left:5% !important;
    padding-right:5% !important;
  }
  .contactimg{
    height:270px !important;
  }
  :where(.css-dev-only-do-not-override-diro6f).ant-card .ant-card-body {
    padding: 24px;
    border-radius: 0 0 8px 8px;
    margin-bottom: -118px;
}
 
}


